import React from 'react'
import styled from 'styled-components'

// Elements

interface TitleDefaultProps {
  className?: string
  isCentered?: any
}

const TitleWrapper = styled.div<{ isCentered: boolean }>`
  position: relative;
  padding-bottom: 1rem;
  &:after {
    content: '';
    position: absolute;
    width: ${(props) => (props.isCentered ? '9%' : '30%')};
    height: 3px;
    bottom: 0;
    left: ${(props) => (props.isCentered ? '45%' : '0')};
    background-color: ${({ theme }) => theme.color.secondary};
    visibility: visible;
    transition: all 0.3s ease-in-out;
  }
`

const TitleDefault: React.FC<TitleDefaultProps> = ({
  children,
  className = '',
  isCentered,
}) => (
  <TitleWrapper isCentered={isCentered} className={className}>
    {children}
  </TitleWrapper>
)

export default TitleDefault
