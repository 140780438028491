import React from 'react'

// Components
import Text from './Text'
import TextWithTitle from './TextWithTitle'
import TextWithUsp from './TextWithUsp'

interface TextShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

interface TextProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({ fields, location = {} }) => {
  const text: TextProps = {
    default: Text,
    withtitle: TextWithTitle,
    withusp: TextWithUsp,
  }

  if (!fields.styletype || !text[fields.styletype]) {
    return null
  }

  const Component = text[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
