import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import styled from 'styled-components'

const Wrapper = styled.section<{ bgColor?: string }>`
  background-color: ${(props) =>
    props.bgColor === 'light' ? props.theme.color.background : 'transparent'};
`

const Content = styled(ParseContent)<{ bgColor?: string }>`
  font-size: ${(props) =>
    props.bgColor === 'light'
      ? props.theme.font.size.amazing
      : props.theme.font.size.large};

  & h3 {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
    letter-spacing: 1.6px;
  }

  & ul {
    font-weight: ${({ theme }) => theme.font.weight.light};
    letter-spacing: 1.6px;
  }

  & .unique-small {
    font-size: 22px;
  }

  & p:first-child {
    line-height: ${(props) =>
      props.bgColor === 'light' ? props.theme.font.size.mega : 'auto'};
    @media (max-width: 767px) {
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.font.size.big} !important;
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <Wrapper className="mb-5 py-5" bgColor={fields.backgroundcolor}>
    <div className="container py-lg-5">
      <Content content={fields.description} bgColor={fields.backgroundcolor} />
    </div>
  </Wrapper>
)

export default Text
