import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/Text/TitleDefault'

const Content = styled(ParseContent)`
  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
  & ul {
    padding-left: 0;
    list-style: none;

    & li:before {
      content: '• ';
      color: ${({ theme }) => theme.color.secondary};
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }
`

interface TextWithTitleProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithTitle: React.FC<TextWithTitleProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <div className="row">
            <div className="col-sm-4">
              <TitleDefault>
                <ParseContent content={fields.title} />
              </TitleDefault>
            </div>
            <div className="col-sm-8 d-flex justify-content-center mt-4 mt-sm-0">
              <Content
                content={fields.description}
                className="d-flex flex-column justify-content-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextWithTitle
