import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/Text/TitleDefault'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.big};
  }

  & ul {
    padding-left: 0;
    list-style: none;

    & li:before {
      content: '• ';
      color: ${({ theme }) => theme.color.secondary};
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }
`

interface TextWithUspProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithUsp: React.FC<TextWithUspProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-5">
      <TitleDefault isCentered>
        <ParseContent content={fields.title} />
      </TitleDefault>
      <div className="row justify-content-center mt-5">
        {fields.usp?.map((usp, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-sm-6 " key={index}>
            <div className="py-4 px-md-5 px-2">
              <Content content={usp?.description} />
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default TextWithUsp
